import React from "react";
import PropTypes from "prop-types";
import { Card, CardContent, CardActions, Button, Typography } from "@mui/material";

const SubscriptionCard = ({ title, price, features, buttonText, onSubscribe }) => {
  return (
    <Card
      sx={{
        maxWidth: "20rem",
        margin: "auto",
        boxShadow: 3,
      }}
    >
      <CardContent>
        <Typography variant="h5" component="div" sx={{ fontWeight: "bold", mb: 2 }}>
          {title}
        </Typography>
        <Typography variant="h4" component="div" sx={{ fontWeight: "bold", color: "indigo.600", mb: 4 }}>
          ${price}/mo
        </Typography>
        <ul style={{ paddingLeft: "1.25rem", marginTop: "1rem", color: "#4B5563" }}>
          {features.map((feature, index) => (
            <li key={index} style={{ marginBottom: "0.5rem" }}>
              {feature}
            </li>
          ))}
        </ul>
      </CardContent>
      <CardActions sx={{ justifyContent: "center", mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubscribe}
          aria-label={`Subscribe to ${title}`}
        >
          {buttonText}
        </Button>
      </CardActions>
    </Card>
  );
};

SubscriptionCard.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  buttonText: PropTypes.string.isRequired,
  onSubscribe: PropTypes.func.isRequired,
};

export default SubscriptionCard;
