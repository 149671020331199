import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';

const PrivacyPolicyPage = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Box textAlign="center" mb={4}>
          <Typography variant="h4">Privacy Policy</Typography>
        </Box>
        <Box>
          <Typography variant="h6" gutterBottom>
            1. Introduction
          </Typography>
          <Typography variant="body1" paragraph>
            At InkD App, we respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and share your data when you use our platform.
          </Typography>

          <Typography variant="h6" gutterBottom>
            2. Information We Collect
          </Typography>
          <Typography variant="body1" paragraph>
            We may collect the following types of information:
            <ul>
              <li>Personal Information: Name, email address, phone number, and payment details.</li>
              <li>Content: Photos, portfolios, messages, and other user-generated content.</li>
              <li>Usage Data: Device information, IP address, and browsing activity on our platform.</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom>
            3. How We Use Your Information
          </Typography>
          <Typography variant="body1" paragraph>
            Your information is used for the following purposes:
            <ul>
              <li>To provide and improve our platform.</li>
              <li>To process transactions and manage subscriptions.</li>
              <li>To communicate with you about updates, offers, and support.</li>
              <li>To enforce our terms and policies.</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom>
            4. Sharing Your Information
          </Typography>
          <Typography variant="body1" paragraph>
            We do not sell your personal information. However, we may share it with:
            <ul>
              <li>Service Providers: Payment processors, hosting providers, and analytics tools.</li>
              <li>Legal Authorities: To comply with legal obligations or enforce our policies.</li>
              <li>Business Partners: In the event of a merger, acquisition, or similar transaction.</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom>
            5. Data Retention
          </Typography>
          <Typography variant="body1" paragraph>
            We retain your data as long as necessary to provide our services or comply with legal requirements. You may request the deletion of your personal data at any time by contacting us.
          </Typography>

          <Typography variant="h6" gutterBottom>
            6. Your Rights
          </Typography>
          <Typography variant="body1" paragraph>
            Depending on your location, you may have the following rights:
            <ul>
              <li>Access: View the personal data we have about you.</li>
              <li>Correction: Update any inaccurate or incomplete information.</li>
              <li>Deletion: Request the deletion of your personal data.</li>
              <li>Objection: Opt-out of certain uses of your data, such as marketing communications.</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom>
            7. Security
          </Typography>
          <Typography variant="body1" paragraph>
            We use industry-standard security measures to protect your data. However, no method of transmission or storage is 100% secure, and we cannot guarantee absolute security.
          </Typography>

          <Typography variant="h6" gutterBottom>
            8. Changes to This Policy
          </Typography>
          <Typography variant="body1" paragraph>
            We may update this Privacy Policy from time to time. We encourage you to review it periodically for any changes. Your continued use of our platform constitutes acceptance of the updated terms.
          </Typography>

          <Typography variant="h6" gutterBottom>
            9. Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any questions or concerns about this Privacy Policy feel free to use the Contact link.
          </Typography>

          <Typography variant="body1" color="textSecondary" paragraph>
            Effective Date: January 1, 2024
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default PrivacyPolicyPage;
