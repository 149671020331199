import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Badge,
  Divider,
} from "@mui/material";
import { useUserType } from "../contexts/UserTypeContext";

const MessagesPage = () => {
  const { userType } = useUserType(); // Access userType from context
  const [messages, setMessages] = useState([]);

  // Simulate fetching messages based on user type
  useEffect(() => {
    const fetchMessages = async () => {
      const mockMessages = {
        user: [
          { id: 1, sender: "Artist A", content: "Hello User!", isNew: true },
          { id: 2, sender: "Studio X", content: "We have an update for you.", isNew: false },
          { id: 3, sender: "Artist A", content: "Hello User!", isNew: false },
          { id: 4, sender: "Studio X", content: "We have an update for you.", isNew: false },
        ],
        artist: [
          { id: 1, sender: "User B", content: "I'm interested in your work.", isNew: true },
          { id: 2, sender: "Studio Y", content: "Collaboration opportunity!", isNew: true },
          { id: 3, sender: "Artist D", content: "Looking to join your studio.", isNew: false },
          { id: 4, sender: "User D", content: "I'd be happy to work on your tattoo", isNew: false },
        ],
        studio: [
          { id: 1, sender: "User C", content: "Can I book an appointment?", isNew: true },
          { id: 2, sender: "Artist D", content: "Looking to join your studio.", isNew: false },
          { id: 3, sender: "Artist D", content: "Looking to join your studio.", isNew: false },
          { id: 4, sender: "User B", content: "Can I book an appointment?", isNew: false },
        ],
        
      };

      setMessages(mockMessages[userType] || []);
    };

    fetchMessages();
  }, [userType]);

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        {userType
          ? `${userType.charAt(0).toUpperCase() + userType.slice(1)}'s Messages`
          : "Messages"}
      </Typography>
      <Box sx={{ bgcolor: "#f9f9f9", p: 2, borderRadius: 1, boxShadow: 2 }}>
        <List>
          {messages.map((message) => (
            <React.Fragment key={message.id}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={message.sender}
                  secondary={message.content}
                />
                {message.isNew && (
                  <Badge
                    color="error"
                    badgeContent="New"
                    sx={{ ml: 2 }}
                  />
                )}
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
        {messages.length === 0 && (
          <Typography
            variant="body1"
            color="text.secondary"
            textAlign="center"
            sx={{ mt: 2 }}
          >
            No messages to display.
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default MessagesPage;
