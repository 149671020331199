import React from "react";
import { AppBar, Toolbar, Button, Box } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InkD1 from "../assets/InkD1.jpg";

const NavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const restrictedRoutes = [
    "/user/dashboard",
    "/artist/dashboard",
    "/studio/dashboard",
    "/user/portfolio",
    "/artist/portfolio",
    "/studio/portfolio",
    "/about",
    "/contact",
    "/chat",
    "/features",
    "/messages",
    "/notifications",
    "/privacy-policy",
    "/user-agreement",
    "/billing",
    "/jobs",
    "/user/submit-project",
    "/artist/proposal-submit",
    "/studio/proposal-submit",
    '/subscriptions',
  ];

  const isRestrictedRoute = restrictedRoutes.some((path) =>
    location.pathname.includes(path)
  );

  const isArtistDashboard = location.pathname.includes("/artist/dashboard");
  const isStudioDashboard = location.pathname.includes("/studio/dashboard");

  const getFallbackRoute = () => {
    if (location.pathname.includes("/dashboard")) return "/";
    if (location.pathname.includes("/portfolio")) return `/${location.pathname.split('/')[1]}/dashboard`;
    return "/";
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundImage: `url(${InkD1})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "200px",
        display: "flex",
        width: "100%",
        justifyContent: location.pathname === "/" ? "flex-start" : "flex-end",
        boxShadow: "none",
      }}
    >
      {location.pathname !== "/" && (
        <Toolbar
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            width: "auto",
            display: "flex",
            justifyContent: "space-between",
            height: "50px",
          }}
        >
          <Box sx={{ display: "flex", gap: 3 }}>
            <Button
              component={Link}
              to="/"
              sx={{
                color: "white",
                fontWeight: "bold",
                textTransform: "none",
                fontSize: "18px",
              }}
            >
              Home
            </Button>

            {isRestrictedRoute && (
              <Button
                onClick={() => {
                  if (window.history.length > 1) {
                    navigate(-1);
                  } else {
                    navigate(getFallbackRoute());
                  }
                }}
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  textTransform: "none",
                  fontSize: "18px",
                }}
              >
                Back
              </Button>
            )}

            {(isArtistDashboard || isStudioDashboard) && (
              <Button
                component={Link}
                to="/jobs"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  textTransform: "none",
                  fontSize: "18px",
                }}
              >
                Jobs
              </Button>
            )}
          </Box>
        </Toolbar>
      )}
    </AppBar>
  );
};

export default NavBar;
