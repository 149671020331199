import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  InputLabel,
} from '@mui/material';

const ProposalSubmissionPage = () => {
  const [file, setFile] = useState(null);

  const handleFileUpload = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Proposal submitted successfully!');
    // Placeholder for API call to submit proposal
  };

  return (
    <Paper elevation={3} sx={{ padding: 4, margin: '20px auto', maxWidth: 600 }}>
      <Typography variant="h4" gutterBottom>
        Submit Your Tattoo Proposal
      </Typography>
      <form onSubmit={handleSubmit} style={{ placeContent: "center" }}>
        <Box mb={3}>
          <TextField
            fullWidth
            label="Name"
            variant="outlined"
            required
          />
        </Box>
        <Box mb={3}>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            required
            type="email"
          />
        </Box>
        <Box mb={3}>
          <TextField
            fullWidth
            label="Tattoo Description"
            variant="outlined"
            required
            multiline
            rows={4}
          />
        </Box>
        <Box mb={3}>
          <InputLabel htmlFor="file-upload">Upload Reference Images</InputLabel>
          <Button variant="contained" component="label">
            Upload File
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={handleFileUpload}
            />
          </Button>
          {file && (
            <Typography variant="body2" color="textSecondary" mt={1}>
              Selected File: {file.name}
            </Typography>
          )}
        </Box>
        <Button variant="contained" color="primary" type="submit">
          Submit Proposal
        </Button>
      </form>
    </Paper>
  );
};

export default ProposalSubmissionPage;
