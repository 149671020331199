import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { UserTypeProvider } from "./contexts/UserTypeContext";
import { SubscriptionProvider } from "./contexts/SubscriptionContext";
import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <SubscriptionProvider>
    <UserTypeProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </UserTypeProvider>
    </SubscriptionProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
