import { Routes, Route } from 'react-router-dom';
import Signup from './pages/Signup';
import Login from './pages/Login.jsx';
import Navbar from './components/NavBar.jsx';
import Footer from './components/Footer.jsx';
import UserDashboard from './pages/UserDashboard.jsx';
import UserPortfolioPage from './pages/UserPortfolioPage.jsx';
import ArtistDashboard from './pages/ArtistDashboard.jsx';
import ArtistPortfolioPage from './pages/ArtistPortfolioPage';
import StudioDashboard from './pages/StudioDashboard.jsx';
import StudioPortfolioPage from './pages/StudioPortfolioPage.jsx';
import NotificationsPage from 'pages/NotificationsPage';
import ChatPage from 'pages/ChatPage';
import MessagesPage from 'pages/MessagesPage';
import BillingPage from 'pages/BillingPage';
import UserAgreementPage from 'pages/UserAgreementPage';
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage';
import HomePage from 'pages/HomePage';
import FeaturesPage from 'pages/FeaturesPage';
import AboutPage from 'pages/AboutPage';
import ContactPage from 'pages/ContactPage';
import JobSubmissionForm from 'pages/JobSubmissionForm';
import ProposalSubmissionPage from 'pages/ProposalSubmissionPage';
import NotFoundPage from 'pages/NotFoundPage';
import ScrollToTop from './components/ScrollToTop';
import JobsPage from 'pages/JobsPage';
import SubscriptionsPage from 'pages/SubscriptionsPage';

const App = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <ScrollToTop />
      <Navbar />
      <main className="flex-1">
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<HomePage />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path='/jobs' element={<JobsPage />} />

          {/* User-Specific Routes */}
          <Route path="/user/dashboard" element={<UserDashboard />} />
          <Route path="/user/portfolio" element={<UserPortfolioPage />} />
          <Route path='/user/submit-project' element={<JobSubmissionForm />} />
          <Route path="/user/notifications" element={<NotificationsPage />} />
          <Route path="/user/chat" element={<ChatPage />} />
          <Route path="/user/messages" element={<MessagesPage />} />
          <Route path="/user/billing" element={<BillingPage />} />
          <Route path="/user/user-agreement" element={<UserAgreementPage />} />
          <Route path="/user/privacy-policy" element={<PrivacyPolicyPage />} />

          {/* Artist-Specific Routes */}
          <Route path="/artist/dashboard" element={<ArtistDashboard />} />
          <Route path="/artist/portfolio" element={<ArtistPortfolioPage />} />
          <Route path="/artist/notifications" element={<NotificationsPage />} />
          <Route path="/artist/chat" element={<ChatPage />} />
          <Route path="/artist/messages" element={<MessagesPage />} />
          <Route path="/artist/proposal-submit" element={<ProposalSubmissionPage />} />
          <Route path="/artist/billing" element={<BillingPage />} />
          <Route path="/artist/user-agreement" element={<UserAgreementPage />} />
          <Route path="/artist/privacy-policy" element={<PrivacyPolicyPage />} />


          {/* Studio-Specific Routes */}
          <Route path="/studio/dashboard" element={<StudioDashboard />} />
          <Route path="/studio/portfolio" element={<StudioPortfolioPage />} />
          <Route path="/studio/notifications" element={<NotificationsPage />} />
          <Route path="/studio/chat" element={<ChatPage />} />
          <Route path="/studio/messages" element={<MessagesPage />} />
          <Route path='/studio/proposal-submit' element={<ProposalSubmissionPage />} />
          <Route path="/studio/billing" element={<BillingPage />} />
          <Route path="/studio/user-agreement" element={<UserAgreementPage />} />
          <Route path="/studio/privacy-policy" element={<PrivacyPolicyPage />} />

          {/* Feature Routes */}
          <Route path="/features" element={<FeaturesPage />} />

          {/* Subscriptions Routes */}
          <Route path="/subscriptions" element={<SubscriptionsPage />} />

          {/* Legal Routes */}
          <Route path="/billing" element={<BillingPage />} />
          <Route path="/user-agreement" element={<UserAgreementPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

          {/* Fallback Route */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

export default App;
