
import { AppBar, Toolbar, Typography, Button, Card, Grid, CardContent } from '@mui/material';

const HomePage = () => {
  return (
    <>
      {/* Navbar */}
      <AppBar position="static">
        <Toolbar
          sx={{
            backgroundColor: "black", // Transparent overlay
            width: "auto",
            display: "flex",
            justifyContent: "center",
            height: "50px", // Adjust the height of the toolbar
          }}
        >
          {/* <Button color="inherit" href='/signup' disabled>Sign Up</Button>
          <Button color="inherit" href='/login' disabled>Login</Button> */}
          <Button color="inherit" href="/about">About</Button>
          <Button color="inherit" href="/contact">Contact</Button>
        </Toolbar>
      </AppBar>

      {/* Hero Banner */}
      <div style={{ padding: '40px', textAlign: 'center', backgroundColor: '#f5f5f5', color: "black"}}>
      <Typography variant="h4" gutterBottom color='#8B0000'>
          <b>Your Body, Your Canvas</b>
        </Typography>
        <Typography variant="h6" gutterBottom>
          We are connecting everyone to their perfect tattoo experience
        </Typography>
        <Typography variant="h6" gutterBottom>
          Find the best tattoo artists, local studios to submit your tattoo ideas direct
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          <b>Find the perfect tattoo equipment from top brands</b>
        </Typography>
        <h1 style={{ color: '#8B0000' }}>
          Coming Soon!
        </h1>
        <Button variant="contained" color="primary" mat-raised-button href="/features">
          Learn More
        </Button>
      </div>

      {/* Feature Highlights */}
      <Grid container spacing={1} style={{ padding: '10px' }}>
        {[
          { title: 'Find Artists', description: 'Find the perfect artist or studio near you.' },
          { title: 'Submit Proposals', description: 'Share your tattoo ideas and find the perfect artist.' },
          { title: 'Book Appointments', description: 'Reserve slots at local studios.' },
          { title: 'Shop Equipment', description: 'Find the best tattoo equipment from top brands.' },
          { title: 'Get Inspired', description: 'Learn from the best through our various blogs.' },
          { title: 'Chat Directly', description: 'Communicate with artists and studios.' },
        ].map((feature, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <Card>
              <CardContent>
                <Typography variant="h5">{feature.title}</Typography>
                <Typography variant="body2">{feature.description}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default HomePage;
