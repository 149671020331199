import React from "react";
import SubscriptionCard from "../components/SubscriptionCard";
import { useSubscription } from "../contexts/SubscriptionContext";
import { useUserType } from "../contexts/UserTypeContext";

const SubscriptionsPage = () => {
  const { setSubscriptionData } = useSubscription();
  const { userType } = useUserType(); // Get the userType from context

  const handleSubscriptionUpdate = (category, tier) => {
    const updatedSubscription = {
      category,
      plan: tier.title,
      amount: `$${tier.price}/month`,
    };

    setSubscriptionData(updatedSubscription);
    alert(`Subscribed to ${tier.title} (${category})!`);
  };

  // Define subscription categories based on userType
  const subscriptionCategories = {
    user: [
      {
        category: "Users",
        tiers: [
          {
            title: "Basic",
            price: "5",
            features: ["Access to artist profiles", "Studio searches", "Job board postings"],
            buttonText: "Subscribe",
          },
          {
            title: "Plus",
            price: "10",
            features: ["Enhanced search filters", "Direct chat", "Priority booking"],
            buttonText: "Subscribe",
          },
          {
            title: "Premiere",
            price: "20",
            features: ["Early access to top-rated artists", "Personalized consultation options"],
            buttonText: "Subscribe",
          },
        ],
      },
    ],
    artist: [
      {
        category: "Artists",
        tiers: [
          {
            title: "Basic",
            price: "15",
            features: ["Portfolio hosting", "Job board access"],
            buttonText: "Subscribe",
          },
          {
            title: "Plus",
            price: "30",
            features: ["Visibility boosts", "Client management tools"],
            buttonText: "Subscribe",
          },
          {
            title: "Premiere",
            price: "50",
            features: ["Enhanced marketing tools", "Featured listings", "Premium analytics"],
            buttonText: "Subscribe",
          },
        ],
      },
    ],
    studio: [
      {
        category: "Studios",
        tiers: [
          {
            title: "Standard",
            price: "50",
            features: ["Studio profile", "Artist roster", "Operating hours management"],
            buttonText: "Subscribe",
          },
          {
            title: "Premium",
            price: "100",
            features: ["Enhanced visibility", "Direct links to artists", "Booking tools"],
            buttonText: "Subscribe",
          },
          {
            title: "Enterprise",
            price: "250",
            features: ["Advanced analytics", "Top-tier advertising placements", "Talent scouting tools"],
            buttonText: "Subscribe",
          },
        ],
      },
    ],
  };

  const categoriesToDisplay = subscriptionCategories[userType] || [];

  return (
    <div className="bg-gradient-to-br from-blue-50 to-indigo-100 min-h-screen py-12">
      <div className="max-w-7xl mx-auto px-4">
        <h1 className="text-4xl font-extrabold text-center text-gray-800 mb-12">
          Choose Your Subscription
        </h1>
        {categoriesToDisplay.map((category, index) => (
          <div key={index} className="mb-20">
            <h2 className="text-3xl font-semibold text-center text-gray-700 mb-8">
              {category.category}
            </h2>
            <div className="flex flex-wrap justify-center gap-8">
              {category.tiers.map((tier, tierIndex) => (
                <SubscriptionCard
                  key={tierIndex}
                  title={tier.title}
                  price={tier.price}
                  features={tier.features}
                  buttonText={tier.buttonText}
                  onSubscribe={() => handleSubscriptionUpdate(category.category, tier)}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubscriptionsPage;
