import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Divider,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useUserType } from "../contexts/UserTypeContext";

const ChatPage = () => {
  const { userType } = useUserType(); // Access userType from context
  const location = useLocation();
  const recipient = location.state?.recipient || "Unknown";

  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");

  const handleSend = () => {
    if (input.trim()) {
      setMessages([...messages, { text: input, sender: userType || "You" }]);
      setInput("");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "#f4f6f8",
      }}
    >
      {/* Chat Header */}
      <Box
        sx={{
          padding: 2,
          backgroundColor: "#263238",
          color: "#fff",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Chat with {recipient}
        </Typography>
      </Box>

      {/* Main Content */}
      <Box
        sx={{
          display: "flex",
          flex: 1,
          maxWidth: "1200px",
          margin: "0 auto",
          width: "100%",
          border: "1px solid #e0e0e0",
          backgroundColor: "#fff",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        {/* Chat Messages */}
        <Box
          sx={{
            flexGrow: 1,
            padding: 2,
            overflowY: "auto",
            backgroundColor: "#fafafa",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          {messages.length ? (
            messages.map((message, index) => (
              <Typography
                key={index}
                align={message.sender === (userType || "You") ? "right" : "left"}
                sx={{
                  margin: 1,
                  padding: 1.5,
                  borderRadius: "12px",
                  backgroundColor:
                    message.sender === (userType || "You")
                      ? "#b2dfdb"
                      : "#e3f2fd",
                  color: "#000",
                  maxWidth: "70%",
                  display: "inline-block",
                  wordWrap: "break-word",
                  alignSelf:
                    message.sender === (userType || "You")
                      ? "flex-end"
                      : "flex-start",
                }}
              >
                {message.text}
              </Typography>
            ))
          ) : (
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ textAlign: "center", marginTop: 5 }}
            >
              No messages yet. Start the conversation!
            </Typography>
          )}
        </Box>

        {/* Message Input */}
        <Divider />
        <Box
          sx={{
            display: "flex",
            padding: 2,
            borderTop: "1px solid #ddd",
            position: "relative",
            bottom: 0,
            backgroundColor: "#fff",
            zIndex: 1,
            alignItems: "center",
          }}
        >
          <TextField
            fullWidth
            placeholder="Type a message..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            variant="outlined"
            size="small"
            sx={{ marginRight: 1, borderRadius: "20px" }}
          />
          <IconButton
            color="primary"
            onClick={handleSend}
            sx={{
              backgroundColor: "#81c784",
              "&:hover": { backgroundColor: "#66bb6a" },
              color: "#fff",
            }}
          >
            <SendIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatPage;
