import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Paper, List, ListItem, ListItemText, Button } from '@mui/material';

const ProposalsManagement = ({ proposals = [] }) => {
  return (
    <Paper elevation={3} sx={{ padding: 4, marginTop: 4 }}>
      <Typography variant="h5" gutterBottom>
        Proposals Management
      </Typography>
      {proposals.length > 0 ? (
        <List>
          {proposals.map((proposal) => (
            <ListItem key={proposal.id} sx={{ borderBottom: '1px solid #ddd' }}>
              <ListItemText
                primary={`Client: ${proposal.client}`}
                secondary={`Description: ${proposal.description}, Date: ${proposal.date}`}
              />
              <Button variant="contained" color="primary" sx={{ marginLeft: 2 }}>
                Respond
              </Button>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="body1" color="textSecondary">
          No proposals available.
        </Typography>
      )}
    </Paper>
  );
};

export default ProposalsManagement;
