import React from 'react';
import { Box, Typography, Button, Paper } from '@mui/material';
import { Link } from 'react-router-dom';

const FeaturesPage = () => {
  return (
    <Box sx={{ padding: 4, minHeight: '100vh', backgroundColor: '#f9f9f9' }}>
      <Typography variant="h3" gutterBottom sx={{ textAlign: 'center', marginBottom: 4 }}>
        Demonstration Dashboards
      </Typography>
      <Box sx={{ display: 'grid', gap: 4, gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))' }}>
        <Paper elevation={3} sx={{ padding: 4, textAlign: 'center' }}>
          <Typography variant="h5" gutterBottom>
            Demo User Dashboard
          </Typography>
          <Typography variant="body1" gutterBottom>
            Manage your proposals, view responses, and communicate with artists and studios.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/user/dashboard"
            sx={{ marginTop: 2 }}
          >
            User Dashboard
          </Button>
        </Paper>

        <Paper elevation={3} sx={{ padding: 4, textAlign: 'center' }}>
          <Typography variant="h5" gutterBottom>
            Demo Artist Dashboard
          </Typography>
          <Typography variant="body1" gutterBottom>
            Manage your portfolio, view proposals, and communicate with clients.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/artist/dashboard"
            sx={{ marginTop: 2 }}
          >
            Artist Dashboard
          </Button>
        </Paper>

        <Paper elevation={3} sx={{ padding: 4, textAlign: 'center' }}>
          <Typography variant="h5" gutterBottom>
            Demo Studio Dashboard
          </Typography>
          <Typography variant="body1" gutterBottom>
            List your artists, manage studio information, and collaborate with clients.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/studio/dashboard"
            sx={{ marginTop: 2 }}
          >
            Studio Dashboard
          </Button>
        </Paper>
        <Paper elevation={3} sx={{ padding: 4, textAlign: 'center' }}>
          <Typography variant="h5" gutterBottom>
            Demo User Portfolio
          </Typography>
          <Typography variant="body1" gutterBottom>
            Demo
          </Typography>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to='/user/portfolio'
            sx={{ marginTop: 2 }}
          >
            User Portfolio
          </Button>
        </Paper>
        <Paper elevation={3} sx={{ padding: 4, textAlign: 'center' }}>
          <Typography variant="h5" gutterBottom>
            Artist Portfolio
          </Typography>
          <Typography variant="body1" gutterBottom>
            Demo
          </Typography>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to='/artist/portfolio'
            sx={{ marginTop: 2 }}
          >
            Artist Portfolio
          </Button>
        </Paper>
        <Paper elevation={3} sx={{ padding: 4, textAlign: 'center' }}>
          <Typography variant="h5" gutterBottom>
            Studio Portfolio
          </Typography>
          <Typography variant="body1" gutterBottom>
            Demo
          </Typography>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to='/studio/portfolio'
            sx={{ marginTop: 2 }}
          >
            Studio Portfolio
          </Button>
        </Paper>
      </Box>
    </Box>
  );
};

export default FeaturesPage;
