import * as React from 'react';
import { Container, Box, Typography, Grid, Avatar, List, ListItem, ListItemText, Card, CardMedia, CardContent } from '@mui/material';
import { useUserType } from "../contexts/UserTypeContext";
import studioPhoto from "../assets/studioPhoto.jpg";
import studio1 from "../assets/studio1.jpg";
import studio2 from "../assets/studio2.jpg";

function StudioPortfolioPage() {
  const [messages, setMessages] = React.useState([]);
  const { setUserType } = useUserType('studio');
  setUserType("studio");


  const artists = [
    { name: 'Alex Smith', experience: '10 years' },
    { name: 'Jamie Lee', experience: '7 years' },
  ];

  const gallery = [
    { id: 1, title: 'Studio Work 1', image: '/static/images/gallery/work1.jpg' },
    { id: 2, title: 'Studio Work 2', image: '../assets/studio2.jpg' },
    // Add more images as needed
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Box textAlign="center" mb={4}>
        <Avatar
          alt="Studio Logo"
          src={studioPhoto}
          sx={{ width: 120, height: 120, mx: 'auto', mb: 2 }}
        />
        <Typography variant="h4" gutterBottom>
          InkWorks Studio
        </Typography>
        <Typography variant="body1" color="text.secondary" gutterBottom>
          Location: San Francisco, CA
        </Typography>
        <Typography variant="body1">
          In business for 15 years, providing premium tattoo and piercing services.
        </Typography>
      </Box>

      <Typography variant="h5" gutterBottom>
        Artists
      </Typography>
      <List>
        {artists.map((artist, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={artist.name}
              secondary={`Experience: ${artist.experience}`}
            />
          </ListItem>
        ))}
      </List>

      <Typography variant="h5" gutterBottom mt={4}>
        Gallery
      </Typography>
      <Grid container spacing={2}>
        {gallery.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.id}>
            <Card>
              <CardMedia component="img" height="140" image={studio1} alt={item.title} />
              <CardContent>
                <Typography variant="body2" textAlign="center">
                  {item.title}
                </Typography>
              </CardContent>
            </Card>
            <Card>
              <CardMedia component="img" height="140" image={studio2} alt={item.title} />
              <CardContent>
                <Typography variant="body2" textAlign="center">
                  {item.title}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default StudioPortfolioPage;
