import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Paper } from "@mui/material";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import ProposalsManagement from "../components/ProposalsManagement";
import PrimarySearchAppBar from "../components/PrimarySearchAppBar";
import { useUserType } from "../contexts/UserTypeContext";
import { Link } from "react-router-dom";


const formatTimeTo12Hour = (time) => {
  const [hours, minutes] = time.split(":");
  const hour = parseInt(hours, 10);
  const period = hour >= 12 ? "PM" : "AM";
  const formattedHour = hour % 12 || 12; // Converts 0 to 12 for midnight
  return `${formattedHour}:${minutes} ${period}`;
};

const ArtistDashboard = () => {
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [portfolio, setPortfolio] = useState([]);
  const [availability, setAvailability] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [startTime, setStartTime] = useState("09:00");
  const [endTime, setEndTime] = useState("17:00");

  const handleProfileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePhoto(URL.createObjectURL(file));
    }
  };

  const handlePortfolioUpload = (e) => {
    const files = Array.from(e.target.files).map((file) =>
      URL.createObjectURL(file)
    );
    setPortfolio((prev) => [...prev, ...files]);
  };

  const addAvailability = () => {
    const formattedStartTime = formatTimeTo12Hour(startTime);
    const formattedEndTime = formatTimeTo12Hour(endTime);
    setAvailability((prev) => ({
      ...prev,
      [selectedDate.toDateString()]: { startTime: formattedStartTime, endTime: formattedEndTime },
    }));
  };

  const { userType, setUserType } = useUserType();

  useEffect(() => {
    setUserType("artist");
    console.log("Setting userType to 'artist'");
  }, [setUserType]);

  console.log("Current userType:", userType);

  return (
    <Box sx={{ padding: 4 }}>
      <PrimarySearchAppBar />
      <Typography variant="h4" gutterBottom sx={{ marginTop: 2 }}>
        Artist Dashboard
      </Typography>

      {/* Display userType */}
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        Current User Type: <strong>{userType || "Not Set"}</strong>
      </Typography>

      {/* Profile Photo Section */}
      <Paper elevation={3} sx={{ padding: 2, marginBottom: 4 }}>
        <Typography variant="h6">Profile Photo</Typography>

        <Button variant="contained" component="label" sx={{ marginTop: 2 }}>
          Upload Photo
          <input hidden type="file" accept="image/*" onChange={handleProfileUpload} />
        </Button>
      </Paper>

      {/* Portfolio Section */}
      <Paper elevation={3} sx={{ padding: 2, marginBottom: 4 }}>
        <Typography variant="h6">Portfolio</Typography>
        <Button variant="contained" component="label" sx={{ marginTop: 2, marginBottom: 2 }}>
          Upload Portfolio
          <input hidden type="file" multiple accept="image/*" onChange={handlePortfolioUpload} />
        </Button>
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          {portfolio.map((item, index) => (
            <img
              key={index}
              src={item}
              alt={`Portfolio Image ${index + 1}`}
              width="100"
              style={{ borderRadius: "4px" }}
            />
          ))}
        </Box>
      </Paper>

      {/* Availability Section */}
      <Paper elevation={3} sx={{ padding: 2, marginBottom: 4 }}>
        <Typography variant="h6">Set Availability</Typography>
        <Calendar
          value={selectedDate}
          onChange={setSelectedDate}
          minDate={new Date()}
        />
        <Box sx={{ display: "flex", gap: 2, alignItems: "center", marginBottom: 2 }}>
          <Typography variant="body1">Start Time:</Typography>
          <TimePicker value={startTime} onChange={setStartTime} />
        </Box>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center", marginBottom: 2 }}>
          <Typography variant="body1">End Time:</Typography>
          <TimePicker value={endTime} onChange={setEndTime} />
        </Box>
        <Button variant="contained" onClick={addAvailability}>
          Add Availability
        </Button>
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="h6">Current Availability</Typography>
          {Object.entries(availability).map(([date, times], index) => (
            <Typography key={index}>
              {date}: {times.startTime} - {times.endTime}
            </Typography>
          ))}
        </Box>
      </Paper>
      
      {/* Proposal Submission Form */}
      <Paper elevation={3} sx={{ padding: 2, marginBottom: 4 }}>
    <Link to="/studio/proposal-submit" style={{ textDecoration: "none" }}>
      <Button variant="contained">Submit a Proposal</Button>
    </Link>
  </Paper>

      {/* Proposals Management */}
      <ProposalsManagement proposals={[]} />
    </Box>
  );
};

export default ArtistDashboard;
