import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useUserType } from '../contexts/UserTypeContext';
import { useNavigate } from 'react-router-dom';

const BillingPage = () => {
  const navigate = useNavigate();
  const { userType } = useUserType();
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [paymentHistory, setPaymentHistory] = useState([]);

  useEffect(() => {
    // Mock data for subscription and payment history based on userType
    const mockData = {
      user: {
        subscription: { plan: 'Basic', nextBillingDate: '2024-02-01', amount: '$5/month' },
        history: [
          { date: '2024-01-01', amount: '$5', status: 'Paid' },
          { date: '2023-12-01', amount: '$5', status: 'Paid' },
        ],
      },
      artist: {
        subscription: { plan: 'Pro', nextBillingDate: '2024-02-01', amount: '$30/month' },
        history: [
          { date: '2024-01-01', amount: '$30', status: 'Paid' },
          { date: '2023-12-01', amount: '$30', status: 'Paid' },
        ],
      },
      studio: {
        subscription: { plan: 'Studio Premium', nextBillingDate: '2024-02-01', amount: '$100/month' },
        history: [
          { date: '2024-01-01', amount: '$100', status: 'Paid' },
          { date: '2023-12-01', amount: '$100', status: 'Paid' },
        ],
      },
    };

    // Fetch data based on userType
    const data = mockData[userType] || {};
    setSubscriptionDetails(data.subscription || {});
    setPaymentHistory(data.history || []);
  }, [userType]);

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Box textAlign="center" mb={4}>
          <Typography variant="h4">Billing</Typography>
          <Typography variant="body1" color="textSecondary">
            Manage your subscription and payment history.
          </Typography>
        </Box>

        {/* Subscription Details */}
        <Box mb={4}>
          <Typography variant="h5">Current Subscription</Typography>
          <Typography variant="body1">
            Plan: <strong>{subscriptionDetails.plan || 'N/A'}</strong>
          </Typography>
          <Typography variant="body1">
            Amount: <strong>{subscriptionDetails.amount || 'N/A'}</strong>
          </Typography>
          <Typography variant="body1">
            Next Billing Date: <strong>{subscriptionDetails.nextBillingDate || 'N/A'}</strong>
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={() => navigate('/subscriptions')}
          >
            Update Subscription
          </Button>
        </Box>

        {/* Payment History */}
        <Box>
          <Typography variant="h5" gutterBottom>
            Payment History
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paymentHistory.map((payment, index) => (
                  <TableRow key={index}>
                    <TableCell>{payment.date}</TableCell>
                    <TableCell>{payment.amount}</TableCell>
                    <TableCell>{payment.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </Container>
  );
};

export default BillingPage;
