import React from 'react';
import { Container, Typography, Box, Paper, Button } from '@mui/material';

const AboutPage = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Box textAlign="center" sx={{ mb: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            <b>About Us</b>
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1" color="textSecondary" paragraph>
            Our mission is to develop a premier Tattoo App that brings the community together and fosters creativity. 
            At InkD App, we are dedicated to helping creativity thrive by offering a platform where users, artists, studios, 
            and the tattoo community can flourish at what they do best.
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            Established in 2024, we are proudly based out of Denver, Colorado. InkD App was created and designed by two Senior Software Engineers with 
            over 25 combined years of experience in app development, web apps, cloud architecture, and project management.
          </Typography>
          <Typography variant="h6" paragraph textAlign="center">
            <b>More coming soon!</b>
          </Typography>
          <Box textAlign="center" sx={{ mt: 4 }}>
            <Button variant="contained" color="primary" href="/contact">
              Contact Us
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default AboutPage;
