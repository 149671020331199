import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  InputLabel,
} from '@mui/material';
import ProposalSubmissionPage from './ProposalSubmissionPage';

const JobSubmissionForm = ({ onJobSubmit }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    budget: '',
  });
  const [file, setFile] = useState(null);
  const [showProposalPage, setShowProposalPage] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileUpload = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.name || !formData.description || !formData.budget) {
      alert('Please fill in all required fields.');
      return;
    }

    const jobData = {
      id: Date.now(),
      title: formData.name,
      description: formData.description,
      budget: formData.budget,
      fileName: file?.name || 'No file uploaded',
      date: new Date().toISOString().split('T')[0],
    };

    onJobSubmit(jobData);
    alert('Job submitted successfully!');

    setFormData({ name: '', description: '', budget: '' });
    setFile(null);
  };

  if (showProposalPage) {
    return <ProposalSubmissionPage />;
  }

  return (
    <Paper elevation={3} sx={{ padding: 4, margin: '20px auto', maxWidth: 600 }}>
      <Typography variant="h4" gutterBottom>
        Submit Your Tattoo Project
      </Typography>
      <form onSubmit={handleSubmit} style={{ placeContent: "center" }}>
        <Box mb={3}>
          <TextField
            fullWidth
            label="Name"
            name="name"
            variant="outlined"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </Box>
        <Box mb={3}>
          <TextField
            fullWidth
            label="Description of Tattoo"
            name="description"
            variant="outlined"
            value={formData.description}
            onChange={handleChange}
            required
            multiline
            rows={4}
          />
        </Box>
        <Box mb={3}>
          <TextField
            fullWidth
            label="Budget"
            name="budget"
            variant="outlined"
            value={formData.budget}
            onChange={handleChange}
            required
          />
        </Box>
        <Box mb={3}>
          <InputLabel htmlFor="file-upload">Upload Photos/Drawings</InputLabel>
          <Button variant="contained" component="label">
            Upload File
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={handleFileUpload}
            />
          </Button>
          {file && (
            <Typography variant="body2" color="textSecondary" mt={1}>
              Selected File: {file.name}
            </Typography>
          )}
        </Box>
        <Button variant="contained" color="primary" type="submit">
          Submit Project
        </Button>
      </form>
    </Paper>
  );
};

export default JobSubmissionForm;
