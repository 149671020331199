import { useEffect } from 'react';
import { Container, Box, Avatar, Typography } from '@mui/material';
import { useUserType } from "../contexts/UserTypeContext";
import userPhoto from "../assets/userPhoto.jpg";


function UserPortfolioPage() {
  const { userType, setUserType } = useUserType();

  useEffect(() => {
    setUserType("user");
    console.log("User type set to:", "user"); // Debugging
  }, [setUserType]);

  console.log("Current userType:", userType); // Debugging

  return (
    <Container maxWidth="sm" sx={{ textAlign: 'center', mt: 4, p: 2 }}>
      <Avatar
        alt="User Profile Picture"
        src={userPhoto}
        sx={{ width: 120, height: 120, mx: 'auto', mb: 2 }}
      />
      <Typography variant="h4" gutterBottom>
        {userType || "John Doe"} {/* Display userType dynamically */}
      </Typography>
      <Typography variant="body1" color="text.secondary" gutterBottom>
        Location: Los Angeles, CA
      </Typography>
      <Typography variant="body1">
        Passionate about art and creativity. Always looking for the next masterpiece.
      </Typography>
    </Container>
  );
}

export default UserPortfolioPage;
