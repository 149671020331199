import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Badge,
} from '@mui/material';

const NotificationsPage = () => {
  // Sample notifications data
  const [notifications, setNotifications] = useState([
    { id: 1, title: 'New Message from Artist A', date: '2024-01-01', isNew: true },
    { id: 2, title: 'Your subscription has been updated', date: '2024-01-02', isNew: true },
    { id: 3, title: 'Studio X has accepted your proposal', date: '2024-01-03', isNew: true },
    { id: 4, title: 'Artist B liked your portfolio', date: '2024-01-04', isNew: false },
  ]);

  return (
    <Container
      maxWidth="md"
      sx={{
        mt: 4,
        backgroundColor: 'white',
        padding: 3,
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Box textAlign="center" mb={3}>
        <Typography variant="h4" component="h1">
          Notifications
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Stay updated with your latest notifications.
        </Typography>
      </Box>
      <List>
        {notifications.map((notification) => (
          <React.Fragment key={notification.id}>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    fontWeight={notification.isNew ? 'bold' : 'normal'}
                  >
                    {notification.title}
                  </Typography>
                }
                secondary={`Date: ${notification.date}`}
              />
              {notification.isNew && (
                <Badge
                  color="error"
                  badgeContent="New"
                  sx={{ marginLeft: 2 }}
                />
              )}
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
      {notifications.length === 0 && (
        <Typography
          variant="body1"
          color="textSecondary"
          textAlign="center"
          mt={4}
        >
          No notifications at the moment.
        </Typography>
      )}
    </Container>
  );
};

export default NotificationsPage;
