import React from "react";
import { useNavigate } from "react-router-dom";
import { useUserType } from "../contexts/UserTypeContext";

const Login = () => {
  const { userType, setUserType } = useUserType();
  const navigate = useNavigate();

  const handleLogin = (type) => {
    setUserType(type); // Update the context
    if (type === "user") navigate("/user/dashboard");
    if (type === "artist") navigate("/artist/dashboard");
    if (type === "studio") navigate("/studio/dashboard");
  };

  return (
    <div>
      <h1>Login</h1>
      <p>Current user type: {userType || "None"}</p>
      <button onClick={() => handleLogin("user")}>Login as User</button>
      <button onClick={() => handleLogin("artist")}>Login as Artist</button>
      <button onClick={() => handleLogin("studio")}>Login as Studio</button>
    </div>
  );
};

export default Login;
