import React, { useState } from 'react';
import { Box, Typography, Paper, Grid } from '@mui/material';
import JobSubmissionForm from './JobSubmissionForm';

const JobsPage = () => {
  const [jobs, setJobs] = useState([
    {
      id: 1,
      title: "Dragon Tattoo",
      description: "Looking for a detailed dragon tattoo on my forearm.",
      budget: "$500",
      date: "2024-12-30",
    },
    {
      id: 2,
      title: "Minimalist Rose",
      description: "Need a small minimalist rose tattoo on my wrist.",
      budget: "$200",
      date: "2024-12-25",
    },
    {
      id: 3,
      title: "Skull Tattoo",
      description: "Looking for a detailed skull tattoo on my forearm.",
      budget: "$300",
      date: "2025-1-20",
    },
    {
      id: 4,
      title: "Minimalist Cross",
      description: "Need a small minimalist Cross tattoo on my wrist.",
      budget: "$200",
      date: "2025-2-12",
    },
    {
      id: 5,
      title: "Sleeve Tattoo",
      description: "Looking to get a half sleeve done on my right arm.",
      budget: "$1000",
      date: "2025-1-15",
    },
    {
      id: 6,
      title: "Back Tattoo",
      description: "Looking for a detailed back tattoo.",
      budget: "$1500",
      date: "2025-3-10",
    },
  ]);

  const handleJobSubmit = (newJob) => {
    setJobs((prevJobs) => [...prevJobs, newJob]);
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Available Jobs
      </Typography>
      <Grid container spacing={3}>
        {jobs.map((job) => (
          <Grid item xs={12} sm={6} md={4} key={job.id}>
            <Paper elevation={3} sx={{ padding: 2 }}>
              <Typography variant="h6" gutterBottom>
                {job.title}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {job.description}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Budget: {job.budget}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Date: {job.date}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default JobsPage;