import React, { useEffect, useState } from 'react';
import { Container, Box, Avatar, Typography, Grid, Card, CardMedia, CardContent, Button, Modal, TextField } from '@mui/material';
import { useUserType } from '../contexts/UserTypeContext';
import profilePhoto from "../assets/profilePhoto.jpg";
import tattoo1 from "../assets/tattoo1.jpg";
import tattoo2 from "../assets/tattoo2.jpg";

function ArtistPortfolioPage() {
  const { setUserType } = useUserType();
  const [open, setOpen] = useState(false);
  const [bookingDetails, setBookingDetails] = useState({ date: '', time: '' });

  useEffect(() => {
    setUserType("artist");
  }, [setUserType]);

  const handleBookingSubmit = () => {
    console.log("Booking submitted:", bookingDetails);
    // Send booking details to backend
    fetch('/api/bookings', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        artistId: 1, // Replace with actual artist ID
        date: bookingDetails.date,
        time: bookingDetails.time,
      }),
    }).then(() => {
      alert("Booking request sent!");
      setOpen(false);
    });
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Box textAlign="center" mb={4}>
        <Avatar
          alt="Artist Profile Picture"
          src={profilePhoto}
          sx={{ width: 120, height: 120, mx: 'auto', mb: 2 }}
        />
        <Typography variant="h4" gutterBottom>
          Alex Smith
        </Typography>
        <Typography variant="body1" color="text.secondary" gutterBottom>
          Location: New York, NY
        </Typography>
        <Typography variant="body1">
          10 years of experience specializing in Tattoos and Piercings. Certified in advanced tattoo techniques.
        </Typography>
      </Box>

      <Typography variant="h5" gutterBottom>
        Gallery
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardMedia component="img" height="140" image={tattoo1} alt="Tattoo 1" />
            <CardContent>
              <Typography variant="body2" textAlign="center">
                Tattoo 1
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardMedia component="img" height="140" image={tattoo2} alt="Tattoo 2" />
            <CardContent>
              <Typography variant="body2" textAlign="center">
                Tattoo 2
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Book Me Section */}
      <Box mt={4} textAlign="center">
        <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
          Book Me
        </Button>
      </Box>

      {/* Booking Modal */}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            p: 4,
            borderRadius: 1,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Book an Appointment
          </Typography>
          <TextField
            fullWidth
            label="Date"
            type="date"
            value={bookingDetails.date}
            onChange={(e) => setBookingDetails({ ...bookingDetails, date: e.target.value })}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Time"
            type="time"
            value={bookingDetails.time}
            onChange={(e) => setBookingDetails({ ...bookingDetails, time: e.target.value })}
            sx={{ mb: 2 }}
          />
          <Button variant="contained" color="primary" fullWidth onClick={handleBookingSubmit}>
            Submit
          </Button>
        </Box>
      </Modal>
    </Container>
  );
}

export default ArtistPortfolioPage;
