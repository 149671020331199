import { createContext, useContext, useState } from 'react';

const SubscriptionContext = createContext();

export const SubscriptionProvider = ({ children }) => {
  const [subscriptionData, setSubscriptionData] = useState({});

  return (
    <SubscriptionContext.Provider value={{ subscriptionData, setSubscriptionData }}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscription = () => useContext(SubscriptionContext);
