import React from 'react';
import { Box, Typography, Container, Paper, IconButton } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

const ContactPage = () => {
    return (
        <Container maxWidth="md" sx={{ mt: 2, mb: 2 }}>
            <Paper elevation={3} sx={{ p: 4 }}>
                {/* Page Title */}
                <Box textAlign="center" sx={{ mb: 3 }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        <b>Contact Us</b>
                    </Typography>

                </Box>

                {/* Bret Bickham Contact */}
                <Box sx={{ mt: 1 }}>
                    <Typography variant="h6" component="p" sx={{ mb: 1 }}>
                        <b>Bret Bickham</b>
                    </Typography>
                    <Typography variant="h6" component="p" sx={{ mb: 1 }}>
                        Email:
                        <IconButton
                            href="mailto:bretbickham@gmail.com"
                            color="primary"
                            aria-label="Email Bret Bickham"
                        >
                            <EmailIcon />
                        </IconButton>
                    </Typography>
                </Box>
                <Box sx={{ mt: 1 }}>
                    <Typography variant="body1" color="textPrimary">
                        | CEO & Founder | Senior Software Engineer | Project Manager | Software Architect |
                    </Typography>
                </Box>

                {/* Michael Collins Contact */}
                <Box sx={{ mt: 3 }}>
                    <Typography variant="h6" component="p" sx={{ mb: 1 }}>
                        <b>Michael Collins</b>
                    </Typography>
                    <Typography variant="h6" component="p" sx={{ mb: 1 }}>
                        Email:
                        <IconButton
                            href="mailto:bretbickham@gmail.com"
                            color="primary"
                            aria-label="Email Michael Collins"
                        >
                            <EmailIcon />
                        </IconButton>
                    </Typography>
                </Box>
                <Box sx={{ mt: 1 }}>
                    <Typography variant="body2" color="textPrimary">
                        | CTO & Co-Founder | Senior Software Engineer | Microsoft Engineer | Cloud Architect |
                    </Typography>
                </Box>
                               {/* Support Contact */}
                               <Box sx={{ mt: 3 }}>
                    <Typography variant="h5" component="p" sx={{ mb: 1 }}>
                        <b>Support</b>
                    </Typography>
                    <Typography variant="h6" component="p" sx={{ mb: 1 }}>
                        Email:
                        <IconButton
                            href="mailto:inkdapp@proton.me"
                            color="primary"
                            aria-label="Email Support"
                        >
                            <EmailIcon />
                        </IconButton>
                    </Typography>
                </Box>
                <Box sx={{ mt: 1 }}>
                    <Typography variant="h6" component="p" sx={{ mb: 1 }}>
                        Phone:
                        <IconButton
                            href="tel:+19702133098"
                            color="primary"
                            aria-label="Call Support"
                        >
                            <PhoneIcon />
                        </IconButton>
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                        | User Support | Information | Product Placement | Studio Help | Artist Help |
                    </Typography>
                </Box>
            </Paper>
        </Container>
    );
};

export default ContactPage;
