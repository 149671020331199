import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';

const UserAgreementPage = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Box textAlign="center" mb={4}>
          <Typography variant="h4">User Agreement</Typography>
        </Box>
        <Box>
          <Typography variant="h6" gutterBottom>
            1. Acceptance of Terms
          </Typography>
          <Typography variant="body1" paragraph>
            By accessing or using our platform, you agree to be bound by these terms and conditions. If you do not agree, please do not use the platform.
          </Typography>

          <Typography variant="h6" gutterBottom>
            2. Content Responsibility
          </Typography>
          <Typography variant="body1" paragraph>
            All creative content, including but not limited to tattoos, portfolios, and messaging, is the sole responsibility of the user who submits it. InkD App does not claim ownership or responsibility for user-generated content.
          </Typography>

          <Typography variant="h6" gutterBottom>
            3. Limitation of Liability
          </Typography>
          <Typography variant="body1" paragraph>
            InkD App is not liable for any disputes, damages, or claims arising from transactions, collaborations, or engagements between users, artists, or studios. Users engage with others at their own risk.
          </Typography>

          <Typography variant="h6" gutterBottom>
            4. Subscription and Billing
          </Typography>
          <Typography variant="body1" paragraph>
            Users are responsible for managing their subscriptions and billing. InkD App reserves the right to suspend accounts for non-payment or violations of terms.
          </Typography>

          <Typography variant="h6" gutterBottom>
            5. Dispute Resolution
          </Typography>
          <Typography variant="body1" paragraph>
            Any disputes arising from the use of this platform must be resolved through binding arbitration. By agreeing to these terms, you waive the right to bring any claims in court.
          </Typography>

          <Typography variant="h6" gutterBottom>
            6. Changes to Terms
          </Typography>
          <Typography variant="body1" paragraph>
            InkD App reserves the right to modify these terms at any time. Continued use of the platform constitutes acceptance of the updated terms.
          </Typography>

          <Typography variant="body1" color="textSecondary" paragraph>
            Effective Date: January 1, 2024
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default UserAgreementPage;
