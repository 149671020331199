import { useState, useEffect } from "react";
import { Box, Typography, Button, Paper } from "@mui/material";
import ProposalsManagement from "../components/ProposalsManagement";
import PrimarySearchAppBar from "../components/PrimarySearchAppBar";
import { useUserType } from "../contexts/UserTypeContext";
import { Link } from "react-router-dom";


const UserDashboard = () => {
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [drawings, setDrawings] = useState([]);

  const { setUserType } = useUserType();
  useEffect(() => {
    setUserType("user");
  }, [setUserType]);

  const handleProfileUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setProfilePhoto(URL.createObjectURL(file));
    } else {
      alert("Please upload a valid image file.");
    }
  };

  const handleDrawingUpload = (e) => {
    const files = Array.from(e.target.files).filter((file) =>
      file.type.startsWith("image/")
    );
    if (files.length < e.target.files.length) {
      alert("Some files were not uploaded because they are not images.");
    }
    const fileURLs = files.map((file) => URL.createObjectURL(file));
    setDrawings((prev) => [...prev, ...fileURLs]);
  };

  return (
    <Box sx={{ padding: 4 }}>
      <PrimarySearchAppBar />
      <Typography variant="h4" gutterBottom sx={{ marginTop: 2 }}>
        User Dashboard
      </Typography>

      {/* Profile Photo Section */}
      <Paper elevation={3} sx={{ padding: 2, marginBottom: 4 }}>
        <Typography variant="h6">Profile Photo</Typography>
        {/* {profilePhoto && (
          <img
            src={profilePhoto}
            alt="Uploaded Profile Photo"
            width="150"
            style={{ borderRadius: "8px" }}
          />
        )} */}
        <Button variant="contained" component="label" sx={{ marginTop: 2 }}>
          Upload Photo
          <input
            hidden
            type="file"
            accept="image/*"
            onChange={handleProfileUpload}
          />
        </Button>
      </Paper>

      {/* Drawings Section */}
      <Paper elevation={3} sx={{ padding: 2, marginBottom: 4 }}>
        <Typography variant="h6">Your Drawings</Typography>
        <Button
          variant="contained"
          component="label"
          sx={{ marginTop: 2, marginBottom: 2 }}
        >
          Upload Drawings
          <input
            hidden
            type="file"
            multiple
            accept="image/*"
            onChange={handleDrawingUpload}
          />
        </Button>
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          {drawings.map((drawing, index) => (
            <img
              key={index}
              src={drawing}
              alt={`Uploaded Drawing ${index + 1}`}
              width="100"
              style={{ borderRadius: "4px" }}
            />
          ))}
        </Box>
      </Paper>
      {/* Job Submission Section */}
      <Paper elevation={3} sx={{ padding: 2, marginBottom: 4 }}>
        <Typography variant="h6">Submit a Job</Typography>
        <Link to="/user/submit-project" style={{ textDecoration: "none" }}>  
          <Button variant="contained" sx={{ marginTop: 2 }}>
            Submit Job
          </Button>
      </Link>
      </Paper>
      {/* Proposals Management Section */}
      <Paper elevation={3} sx={{ padding: 2 }}>
        <Typography variant="h6" gutterBottom>
          Proposals Management
        </Typography>
        <ProposalsManagement proposals={[]} />
      </Paper>
        
      </Box>
  );
};


export default UserDashboard;
