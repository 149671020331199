import { useState, useEffect } from "react";
import { Box, Typography, Button, Paper } from "@mui/material";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import ProposalsManagement from "../components/ProposalsManagement";
import PrimarySearchAppBar from "../components/PrimarySearchAppBar";
import { useUserType } from "../contexts/UserTypeContext";
import { Link } from "react-router-dom";

const formatTimeTo12Hour = (time) => {
  const [hours, minutes] = time.split(":");
  const hour = parseInt(hours, 10);
  const period = hour >= 12 ? "PM" : "AM";
  const formattedHour = hour % 12 || 12; // Converts 0 to 12 for midnight
  return `${formattedHour}:${minutes} ${period}`;
};

const StudioDashboard = () => {
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [portfolioPhoto, setPortfolioPhoto] = useState(null);
  const [hoursOfOperation, setHoursOfOperation] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [startTime, setStartTime] = useState("09:00");
  const [endTime, setEndTime] = useState("17:00");

const { userType, setUserType } = useUserType();

    useEffect(() => {
      setUserType("studio");
    }, [setUserType]);

  const handleProfileUpload = (e) => {
    setProfilePhoto(URL.createObjectURL(e.target.files[0]));
  };

  const handlePortfolioUpload = (e) => {
    setPortfolioPhoto(URL.createObjectURL(e.target.files[0]));
  };

  const addHoursOfOperation = () => {
    const formattedStartTime = formatTimeTo12Hour(startTime);
    const formattedEndTime = formatTimeTo12Hour(endTime);

    setHoursOfOperation((prev) => ({
      ...prev,
      [selectedDate.toDateString()]: {
        startTime: formattedStartTime,
        endTime: formattedEndTime,
      },
    }));
  };

  return (
    <Box sx={{ padding: 4 }}>
      <PrimarySearchAppBar />
      <Typography variant="h4" gutterBottom sx={{ marginTop: 2 }}>
        Studio Dashboard
      </Typography>

      {/* Display userType */}
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        User Type: {userType || "Not Set"}
      </Typography>

      {/* Profile Photo Section */}
      <Paper elevation={3} sx={{ padding: 2, marginBottom: 4 }}>
        <Typography variant="h6">Profile Photo</Typography>
        {profilePhoto && <img src={profilePhoto} alt="Profile" width="150" />}
        <Button variant="contained" component="label" sx={{ marginTop: 2 }}>
          Upload Photo
          <input hidden type="file" onChange={handleProfileUpload} />
        </Button>
      </Paper>

      {/* Hours of Operation */}
      <Paper elevation={3} sx={{ padding: 2, marginBottom: 4 }}>
        <Typography variant="h6">Set Hours of Operation</Typography>
        <Calendar
          value={selectedDate}
          onChange={setSelectedDate}
          minDate={new Date()}
          sx={{ marginBottom: 2 }}
        />
        <Box sx={{ display: "flex", gap: 2, alignItems: "center", marginBottom: 2 }}>
          <Typography variant="body1">Start Time:</Typography>
          <TimePicker value={startTime} onChange={setStartTime} />
        </Box>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center", marginBottom: 2 }}>
          <Typography variant="body1">End Time:</Typography>
          <TimePicker value={endTime} onChange={setEndTime} />
        </Box>
        <Button variant="contained" onClick={addHoursOfOperation}>
          Submit
        </Button>

        <Box sx={{ marginTop: 2 }}>
          <Typography variant="h6">Hours of Operation</Typography>
          {Object.entries(hoursOfOperation).map(([date, times], index) => (
            <Typography key={index}>
              {date}: {times.startTime} - {times.endTime}
            </Typography>
          ))}
        </Box>
      </Paper>

      {/* Portfolio Photo */}
      <Paper elevation={3} sx={{ padding: 2, marginBottom: 4 }}>
        <Typography variant="h6">Studio Portfolio</Typography>
        {portfolioPhoto && <img src={portfolioPhoto} alt="Portfolio" width="150" />}
        <Button variant="contained" component="label" sx={{ marginTop: 2 }}>
          Update
          <input hidden type="file" onChange={handlePortfolioUpload} />
        </Button>
      </Paper>

      {/* Proposal Submission Form */}
      <Paper elevation={3} sx={{ padding: 2, marginBottom: 4 }}>
        <Typography variant="h6">Submit Proposal</Typography>
        <Link to="/artist/proposal-submit" style={{ textDecoration: "none" }}>
        <Button variant="contained">Submit</Button>
        </Link>
      </Paper>

      {/* Proposals Management */}
      <ProposalsManagement proposals={[]} />
    </Box>
  );
};

export default StudioDashboard;
