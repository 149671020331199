import React, { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { useUserType } from '../contexts/UserTypeContext';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default function PrimarySearchAppBar() {
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false); // State for expanding search bar
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { userType } = useUserType(); // Access userType from context

  const isMenuOpen = Boolean(anchorElMenu);

  const navigateTo = (basePath) => {
    if (!userType) return; // Handle edge cases where userType might not be set
    const route = `/${userType}${basePath}`;
    console.log('Navigating to:', route); // Debugging
    navigate(route);
    handleMenuClose();
  };

  const handleMenuOpen = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElMenu(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: 'black' }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ marginRight: 2 }}
            onClick={handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorElMenu}
            open={isMenuOpen}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem onClick={() => navigateTo('/chat')}>Chat</MenuItem>
            <MenuItem onClick={() => navigateTo('/billing')}>Billing</MenuItem>
            <MenuItem onClick={() => navigateTo('/user-agreement')}>User Agreement</MenuItem>
            <MenuItem onClick={() => navigateTo('/privacy-policy')}>Privacy Policy</MenuItem>
          </Menu>

          {!isSearchOpen && (
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: 'none', sm: 'block' } }}
            >
              InkDApp
            </Typography>
          )}

          {isSmallScreen && !isSearchOpen ? (
            <IconButton
              size="large"
              color="inherit"
              aria-label="search"
              sx={{ ml: 2 }}
              onClick={() => setIsSearchOpen(true)} // Open the search bar
            >
              <SearchIcon />
            </IconButton>
          ) : (
            <Search sx={{ flexGrow: isSmallScreen && isSearchOpen ? 1 : 0 }}>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
              />
              {isSmallScreen && isSearchOpen && (
                <IconButton
                  size="small"
                  sx={{ position: 'absolute', right: 10, top: '50%', transform: 'translateY(-50%)' }}
                  onClick={() => setIsSearchOpen(false)} // Close the search bar
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Search>
          )}

          <Box sx={{ flexGrow: 1 }} />

          {/* Messages */}
          <IconButton
            size="large"
            aria-label="show messages"
            color="inherit"
            onClick={() => navigateTo('/messages')}
          >
            <Badge badgeContent={4} color="error">
              <MailIcon />
            </Badge>
          </IconButton>

          {/* Notifications */}
          <IconButton
            size="large"
            aria-label="show notifications"
            color="inherit"
            onClick={() => navigateTo('/notifications')}
          >
            <Badge badgeContent={3} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>

          {/* Portfolio */}
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            color="inherit"
            onClick={() => navigateTo('/portfolio')}
          >
            <AccountCircle />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
